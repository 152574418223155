<script>
import 'vue-croppa/dist/vue-croppa.css';
import env from '@root/env';
import Gen from '../helper/Gen';

export default {
  props: {
    name: String,
    type: String,
    value: String,
    uploadType: {default: ""},
    attr:{default:()=>({})},
    param: {default:()=>({thumbnail:false})},
    galleryParams: {default:()=>({})},
    noHelp: Boolean,
    isFrontend: Boolean,
    required: Boolean,
    noPreview: Boolean,
    isDelete: Boolean,
    isBG: Boolean,
    label: String,
    title:{default: ""}
  },
  data: function () {
    return {
      uploading: false,
      error: false,
      inputFile: null,
      uploadText: this.uploadType == "gallery" ? "Select" : "Upload",
      imgFile: String,
    }
  },
  computed:{
    page(){ return this.$root.page },
    fields(){ return this.$root.fields },
    path(){ return this.$root.app.path },
    uploader(){ return this.$root.app.uploader },
    conf(){ return this.$root.app.uploader[this.type] },
    base(){ return this.$root.baseUrl },
    bindAttr(){
      if(!this.page) return {}
      if(!this.page.validation) return {}
      var attr = Gen.clone(this.attr)
      attr.placeholder = attr.placeholder || this.fields[this.name]
      return Object.assign(this.page.validation(this.name),attr)
    }
  },
  mounted(){
    this.init()
    global.uploader = this
    this.inputFile = $("<input type='file'>")[0]
    this.inputFile.onclick = ()=>{ this.inputFile.value = '' }
    this.inputFile.onchange = this.onUpload
    
    if(this.$slots.btn) this.$slots.btn[0].elm.onclick = this.uploadClick
  },
  methods: {
    init(){
      global.Uploader = this
      if(!this.conf) return
      this.param.type = this.type
      if (!this.uploadType){
        this.param.uploadType = this.conf.img_ratio ? "cropping" : "upload"
      }else{
        this.param.uploadType = Gen.clone(this.uploadType)
      }
      if (this.value) this.uploadText = "Update"
    },
    uploadClick(){
      if(this.uploadType=='gallery') return this.openGallery()
      return this.inputFile.click()
    },
    deleteClick(){
      this.value = ''
      this.$emit('input', '');
    },
    openGallery(){
      global.FileManager.open((files)=>{
        if(files.length){
          var arrayValue = [];
          files.forEach((v)=>{
            arrayValue.push(v.pathfile)
          })
          this.$emit('input', arrayValue)
        }else{
          this.$emit('input', files.pathfile);
        }
        this.$emit('response', this)
        this.valid()
      }, Object.assign({type:this.type}, this.galleryParams))
    },
    onUpload(e){
      this.fileData = $(this.inputFile).prop('files')[0];
      this.conf.fileType = this.fileData.type;
      this.fileType = this.fileData.name.substr(this.fileData.name.lastIndexOf('.') + 1).toLowerCase();
      if (this.conf.rule_size){
        var reader = new FileReader();
        reader.onload = () => {
          var img = new Image;
          img.onload = () => {
            this.img = img
            this.uploadProcess(e)
          }
          img.src = reader.result
        };
        reader.readAsDataURL(this.fileData);
      }else{
        this.uploadProcess(e)
      }
    },
    uploadProcess: function(e){
      // Validation
      this.error = false;
      if (this.conf['rule_type'].indexOf(this.fileType) == -1) {
        this.error = 'File type must be ' + this.conf['rule_type'] + ' type.';
      }
      if (this.fileData.size > this.uploader['max_image_size']) {
        this.error = 'Max file size is '+this.uploader['max_image_size'].byteToSize();
      }
      // if (this.conf.rule_size) if(this.img.naturalWidth<this.conf.rule_size[0]||this.img.naturalHeight<this.conf.rule_size[1]){
      //   this.error = 'Minimum image size is '+this.conf.rule_size[0]+"x"+this.conf.rule_size[1]
      // }
      if (this.error) return alert(this.error);

      // Automatic upload if not image
      if(("jpg,jpeg,png").indexOf(this.fileType) < 0) this.param.uploadType = "upload";

      // Quick Image Upload Filter
      if(this.param.uploadType != "upload"){
        let reader = new FileReader()
        reader.onload = (e)=>{
          this.imageFilter(e.target.result, this.fileData.name)
        }
        reader.readAsDataURL(this.fileData)
        return 
      }

    
      var formData = new FormData();
      formData.append(this.type=="editor" ? 'upload' : 'file', this.fileData);
      this.param.pageType = this.type;
      $.each(this.param, (k, v) => {
        formData.append(k, v);
      });
      var query = {
        token: Gen.getStorage("botk"),
        utoken: Gen.getStorage("fotk"),
      }
      this.uploading = true
      $.ajax({
        url: env.baseUrl + "/ajax/upload?"+Gen.objectToQuery(query),
        type: "POST",
        data: formData,
        enctype: 'multipart/form-data',
        processData: false, // tell jQuery not to process the data
        contentType: false, // tell jQuery not to set contentType
        xhr: ()=>{
          var xhr = new window.XMLHttpRequest();
          var ajax_progress = $('<p style="margin-bottom:0px;" class="label_progress"></p><div class="progress" style="margin:0px 0px 0px;height:20px;"><div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style="width:3%"></div><small class="label_progress" style="color:#fff;"></small></div>');
          $(this.$el).find(".ajax_progress").html(ajax_progress);
          //Upload progress
          xhr.upload.addEventListener("progress", (evt)=>{
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              var label_process = "File Upload: "+(evt.loaded/1000)+  "Kb / " + (evt.total/1000)+"Kb.";
              // Do something with upload progress
              ajax_progress.find('.progress-bar').width((percentComplete*100)+"%");
              $('.label_progress').text(label_process);
              if(percentComplete == 1){
                setTimeout(()=>{
                  ajax_progress.fadeOut(500);
                }, 600);
                setTimeout(()=>{
                  $(this.$el).find(".ajax_progress").html("");
                }, 800);
              }
            }
          }, false);
          //Download progress
          xhr.addEventListener("progress", (evt)=>{
            if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            // Do something with download progress
            // console.log(percentComplete);
            }
          }, false);
          return xhr;
        }
      }).done((resp) => {
        this.uploading = false
        this.uploadText = "Update";
        if (this.param.uploadType == "upload") {
          this.$emit('input', resp.pathfile);
          this.$emit('response', this)
          this.valid()
          return;
        }
      });
    },
    imageFilter(img, filename){
      var query = {
        token: Gen.getStorage("botk"),
        utoken: Gen.getStorage("fotk"),
      }
      global.ImageFilter.open(img, (data) => {
        let form_data = new FormData();
        form_data.append("file", data);
        form_data.append("path", this.type);
        form_data.append("image_name", filename);
        this.$emit("beforeDone", this)
        $.ajax({
          url: env.baseUrl + "/ajax/upload_filter?"+Gen.objectToQuery(query),
          type: "POST",
          data: form_data,
          enctype: 'multipart/form-data',
          processData: false,  // tell jQuery not to process the data
          contentType: false,
          success: (resp) => {
            this.uploading = false
            this.$emit('input', resp.pathfile);
            this.$emit('response', this);
            this.imgFile=resp.targetfile;
            if($("div").hasClass("avatar_prof")){
              $('.avatar_prof').html('<img src="'+resp.targetfile+'" alt="user">');
            }
          }
        });
      }, this.conf);
    },
    valid(){
      if(!$(this.$el).closest("form").length) return;
      setTimeout(()=>{ $(this.$el).find(".image-preview input").valid() }, 100)
    }
  },
  watch:{
    type(){
      setTimeout(()=>{ this.init() },300)
    },
    '$root.app.uploader'(){
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container{
  position: relative;
}
.ajax_progress{
  height: 20px;
  right: 0;
  width: 40%;
  top: 0;
  display: inline-grid;
  position: absolute;
}
.upload-btn{
  position: relative;
  input[type=file]{
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div class="upload-container" v-if="conf">
    <slot name="preview" v-if="!noPreview">
      <div class="image-preview" style="display: block;">
        <div v-if="!param.thumbnail">
          <ImagePreview :title="title" :src="page.uploader(value)" v-if="value"></ImagePreview>
          <input type="hidden" :name="name" :value="value" v-bind="bindAttr">
        </div>
        <div v-else class="mb-2">
          <VImg class="image_thumb" style="width:100%;" :src="page.uploader(value)" v-if="value && !isBG"></VImg>
          <VImg class="image_thumb foot-img" style="width:100%;"  :src="page.uploader(value)" v-if="value && isBG"></VImg>
          <!-- <VImg class="image_thumb" v-for="(v,k) in value" :key="k" :src="uploader(v)"></VImg> -->
        </div>
      </div>
    </slot>
    <div class="clearfix"></div>
    <slot name="help">
      <div class="wrap_info" v-if="!noHelp">
        <p v-if="this.conf.rule_size">Required Size: {{conf.rule_size[0]}}x{{conf.rule_size[1]}}px</p>
        <p>Format: {{conf.rule_type}}. (Max {{uploader["max_image_size"].bytesToSize()}})</p>
      </div>
    </slot>
    <div class="clearfix"></div>
    <slot name="btn mt-2" v-if="!isFrontend">
      <a href="javascript:;" class="btn btn-grey btn-block upload-btn fe-upload-btn" @click="uploadClick">
        <span class="upload-label">
          <slot v-if="!uploading" name="label" :uploadText="uploadText"><i class="fas fa-image"></i> <br> {{uploadText}}</slot>
          <span v-if="uploading"><i class="fas fa-image"></i>Uploading...</span>
        </span>
      </a>
      <a href="javascript:;" class="btn btn-grey btn-block upload-btn fe-upload-btn" v-if="isDelete && value && !isBG" @click="deleteClick">
        <span class="upload-label">Delete</span>
      </a>
    </slot>
    <slot name="btn mt-2" v-else>
      	<div class="file-upload-box" @click="uploadClick">
          <label v-if="!uploading" class="file-upload__label" :uploadText="uploadText"><i class="icon-line2-picture"></i>{{uploadText}} </label>
          <label v-if="uploading" class="file-upload__label"><i class="icon-line2-picture"></i> Uploading... </label>
          <input class="file-upload__input" type="text" name="file-upload">
        </div>
    </slot>
    <!-- <div class="ajax_progress" style="">
    </div> -->
  </div>
</template>

